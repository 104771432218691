import React, { useContext, useEffect, useRef, useState } from "react"
import Hero from "~/components/configurable/Hero"
import Block from "~/components/configurable/Block"
import Heading from "~/components/configurable/Heading"
import Section from "../configurable/Section"
import Col12 from "../grid/Col12"
import "./emergency-workers.scss"
import Col9 from "../grid/Col9"
import Col3 from "../grid/Col3"
import "./animation.scss"
import * as Doctors from "~/vectors/doctors.inline.svg"
import Col5 from "../grid/Col5"
import Col6 from "../grid/Col6"
import BlockCTA from "../configurable/BlockCTA"
import Col7 from "../grid/Col7"
import { GetModal } from "../configurable/Modal"
import Col4 from "../grid/Col4"



const EmergencyWorkers = ({ location }) => {

  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    setHeight(elementRef.current.clientHeight);
  }, []);

  //console.log('helow',height);


  return (
    <div className="refer">

      <Hero imageUrl="/images/banner_new_2.png" compact>
        <Heading level={1} underlined>
        Emergency Workers
        </Heading>
      </Hero>
      <Block>
        <Section>
          <div className="container">
            <Heading level={2}  >
              A huge thank you!
            </Heading>
            <p className="bold-formatting" style={{marginBottom:30}}>
            Green Energy Together, want to say a huge thank you to all our emergency works. Your work does not go unrecognised! As our way to say thank you, we have expanded our Solar PV and battery storage offer to include emergency worker <strong>discounts</strong>. We believe that going green should be available to everyone, but most importantly <strong>affordable for all!</strong>  </p>
            <Heading level={3} underlined >Who we accept  </Heading>
            <div className="row doctors_cards">
              <Col4>
                <img src={require('../../images/doctors/card2.png')} />
                <h4>
                Blue Light Card  
                </h4>
                <p className="bold-formatting" >
                Blue Light Card provides those in the NHS, emergency services, social care sector and armed forces with discounts online and in-store.  
                </p>
              </Col4>
              <Col4>
                <img src={require('../../images/doctors/card3.png')} />
                <h4>
                Defence Discount Service 
                </h4>
                <p className="bold-formatting" >
                Defence Discount Service provides discounts both online and on the high street for members of the Armed Forces, Veterans and Armed Forces Community. 
                </p>
              </Col4>
              <Col4>
                <img src={require('../../images/doctors/card1.png')} />
                <h4>
                Health Services Discounts  
                </h4>
                <p className="bold-formatting" >
                Any role in any healthcare organisation qualifies you to access Health Services Discounts amazing deals and discounts. 
                </p>
              </Col4>
              
            </div>
            <div className="row y-align_row" style={{marginTop:50}}>
              <Col7  >
                <div ref={elementRef} >
                  <div style={{marginTop:50}} >
                    <Heading level={3} underlined >
                    How to claim our discount 
                    </Heading>
                    <p className="bold-formatting">
                    All you need to do is head to our quote tool and fill out all your details. Enter the voucher code <strong>THANK_YOU_2022</strong> <button
                      className="copy_btn"
                      onClick={() => {
                      navigator.clipboard.writeText('THANK_YOU_2022');
                      alert("Link copied!");
                      }}
                      style={{backgroundColor:"transparent",border:"none",cursor:"pointer", borderRadius:"0px"}}
                      ><img style={{borderRadius:"0px"}} src={require('../../images/new_svg_icons/045-copy.svg')}
                     
                      /></button> to get up to 30% off the total cost of a Solar PV and Battery Storage system.  </p>
                    <p className="bold-formatting">
                      

                      Once you have received your online quotation, please respond to the email by adding a copy of your loyalty card. </p>
                      <p className="bold-formatting">

                      

                      In order to qualify for this offer you need to be located in the United Kingdom and you need to be the owner of your house. This offer does not apply to flats.  
                    </p>
                  </div>
                  <div className="refer__button__container">
                    <BlockCTA
                      
                      url="/quote"
                      arrow="right">
                      Get a quote
                    </BlockCTA>
                  </div>

                </div>
              </Col7>
              <Col5  className="y-align_item" style={{maxHeight:`${height}px`}}>
                <Doctors className="hide-under-1k" />
              </Col5>
            </div>
          </div>
        </Section>
      </Block>
    </div>
  )
}

export default EmergencyWorkers
